<template>
  <div class="vg_wrapper">
    <el-tabs
        v-model="activeName"
        type="border-card"
    >
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <ShweDetailMain/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ShweDetailMain from '@/views/ProduceManagement/ShweManage/TabChild/ShweDetailMain.vue'
export default {
    name:'ShweDetail',
    components:{
        ShweDetailMain
    },
    data () {
        return {
            activeName: "first",
        }
    }
}
</script>

<style lang="less" scoped>

</style>