<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <el-form ref="shweForm" :inline="true" :model="shweForm" :rules="rules" label-width="120px" size="mini">
      <el-row>
        <div class="vd_detail">详细信息</div>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="员工卡号" prop="rfid_no">
            <el-input v-model="shweForm.rfid_no" disabled show-word-limit placeholder="请填写员工卡号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" >
          <el-form-item label="员工姓名" prop="shwe_stff_name">
            <el-input v-model="shweForm.shwe_stff_name" disabled show-word-limit placeholder="请填写员工姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="利器名称" prop="shwe_name">
            <el-select disabled v-model="shweForm.shwe_name" placeholder="请选择利器名称" clearable size="small">
              <el-option
                  v-for="item in shewNameList"
                  :key="item.id"
                  :label="item.param1"
                  :value="item.param1">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="部门" prop="shwe_dept_name">
            <el-input v-model="shweForm.shwe_dept_name" disabled show-word-limit placeholder="请填写部门"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="小组" prop="shwe_dept_team_name">
            <el-input v-model="shweForm.shwe_dept_team_name" disabled show-word-limit placeholder="请填写小组"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_button">
        <el-row class="vg_mb_8">
          <el-col :md="8">
            <el-form-item label="操作时间：">
              <div class="block">
                <el-time-picker
                  is-range
                  v-model="searchForm.timeValue"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围">
                </el-time-picker>
              </div>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getShwesNow()" class="vg_ml_16">查询</el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-table ref="multiTable" :data="shweForm.shwe_deta_list" border>
          <el-table-column label="操作类型" prop="shwe_deta_type" :formatter="famterType"/>
          <el-table-column label="操作数量" prop="shwe_deta_num"/>
          <el-table-column label="操作时间" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.create_time">
                {{ scope.row.create_time | formatDate}}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {shweAPI} from "@api/modules/shwe";
import {userAPI} from "@api/modules/user";
import {optnAPI} from "@api/modules/optn";
import {deptAPI} from "@api/modules/department";
import helper from "@assets/js/helper.js"

export default {
  name: 'ShweDetailMain',
  components: {
  },
  data() {
    return {
      rules:{
      },
      searchForm: {
        timeValue:{
          startTime:null,
          endTime:null
        }
      },
      shweForm:{},
      isShow: true,
      btn:{},
      loadingFlag:true,
      deptTeamsGroupOpt:[],
      shewNameList:[],
    }
  },
  created() {
    this.initData()
  },
  watch: {
  },
  filters:{
    // 时间转换
    formatDate(row) {
      return helper.toStringDate(row)
    },
  },
  methods:{
    initData(){
      this.loadingFlag = true
      this.getDeptTeams();
      this.getShewList();
      this.getshweInfo()
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        timeValue:{
          startTime:null,
          endTime:null
        }
      };
      this.initData()
    },
    // 查询方法
    getShwesNow(){
      this.initData()
    },
    // 类型判断
    famterType(val){
      if(val.shwe_deta_type === 0){
        return '发放'
      }else if(val.shwe_deta_type === 1){
        return '更换'
      }else if(val.shwe_deta_type === 2){
        return '回收'
      }
    },
    // 获取小组
    getDeptTeams(flag){
      get(deptAPI.getAllDeptTeams)
      .then(res=>{
        if(res.data.code === 0){
          this.deptTeamsGroupOpt = res.data.data
        }else{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
        }
      }).catch(res =>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 获取利器名称
    getShewList(){
      get(optnAPI.getAllContent,{perm_id : 10004})
      .then(res=>{
        if(res.data.code === 0){
          this.shewNameList = res.data.data
        }
        else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
        let mg = res.data.msg|| '';
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 获取表单信息
    getshweInfo() {
      this.shweForm.shwe_id = this.$route.query.form_id;
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(shweAPI.getShweById,{shwe_id:this.shweForm.shwe_id,start_time:timeNewVal.startTime,end_time:timeNewVal.endTime})
      .then(res=>{
        if (res.data.code === 0) {
          this.shweForm = res.data.data.form;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.loadingFlag = false
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    }
  }
}
</script>

<style scoped lang="scss">
.vd_detail{
  margin-left: 20px;
  margin-bottom: 10px;
  color: #979797;
  font-size: 20px;
  font-weight: 600;
}
.vd_button{
  margin-top: 32px;
  border-bottom: 1px dashed #C0C4CC;
  padding-bottom: 16px;
}
</style>
